import axios from "axios";
import QueryString from "qs";

import cryptoJS from "crypto-js";


export function loginServerRequest(data, callback) {

    const username = data.loginemail, password = data.loginpass;

    var url = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : '';
    url = url.concat(`central/user/login/${username}/${password}`);
    
    console.log(url, process.env.REACT_APP_NODE_ENV)

    const options = {
      method : 'GET',
      url : url,
    };

    axios(options)
    .then((response) => {
        console.log(response);
        console.log(response.data)
        const result = response.data;
        console.log(typeof result);
        
        if(response.data.length && typeof result == 'object') {
            
            console.log('User Verified')

            loginUser(result[0]);

            const res = {
                success : 'true',
                message : 'Correct Credentials'
            };

            callback(res);
            
        } else {
            
            console.log('Wrong Credentials')
            const res = {
                success : 'false',
                message : 'Wrong Credentials'
            };

            callback(res);
        }

    })
    .catch((error) => {
        console.log(error);
    })

}


function loginUser(userdetails) {

    console.log('LoginUser', userdetails);
    
    const isLoggedIn = window.localStorage.getItem('isLoggedIn');
    console.log(isLoggedIn);



    window.localStorage.setItem('isLoggedIn', '1');

    // window.localStorage.setItem('loginusername', JSON.stringify(userdetails.email_id));
    // window.localStorage.setItem('loginpassword', JSON.stringify(userdetails.password));

    
    window.localStorage.setItem('loginusername', cryptoJS.AES.encrypt(JSON.stringify(userdetails.email_id), process.env.REACT_APP_CRYPTO_SECRET_KEY).toString());
    window.localStorage.setItem('loginpassword', cryptoJS.AES.encrypt(JSON.stringify(userdetails.password), process.env.REACT_APP_CRYPTO_SECRET_KEY).toString());

    console.log('LoginUser', userdetails);

    window.localStorage.setItem('userdetails', cryptoJS.AES.encrypt(JSON.stringify(userdetails), process.env.REACT_APP_CRYPTO_SECRET_KEY).toString());

    return userdetails;    
}


function logoutServerRequest(username, password) {

}


export function logoutUser() {

    window.localStorage.setItem('isLoggedIn', '0');
    console.log(window.localStorage.getItem('isLoggedIn'));

    window.localStorage.clear();

    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    console.log(username, password);

    // logoutServerRequest(username, password);

    return 1;
}




export function registerServerRequest(data, callback) {

    console.log(data.registerreferralid.length);

    const reqbody = {
        full_name : data.registerfullname,
        user_name : data.registerusername,
        email_id : data.registeremail,
        password : data.registerpassword,
        point : data.registerreferralid.length == '0' ? 100 : 300,
        notification : 1,
        status : 1
    }

    
    var url = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : '';
    url = url.concat(`central/user`);
    
    const options = {
      method : 'POST',
      url : url,
      data : QueryString.stringify(reqbody),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    };

    axios(options)
    .then((response) => {
        console.log(response)
        console.log(typeof response.data)
        const errno = response.data.errno;
        
        if(typeof response.data == 'string') return;
        if(errno) {
            console.log('Error Detected')
            console.log(response.data);
            
            const sqlMessage = response.data.sqlMessage;
            
            const res = {
                success : 'false',
                message : 'Duplicate Key Entry',
                field : response.data.sqlMessage
            };

            if(sqlMessage.includes('email_id')) {
                res.field = 'email';
            } else if (sqlMessage.includes('user_name')) {
                res.field = 'username';
            }

            callback(res);
            
        } else {
            
            console.log('User Added Successfully')
            const res = {
                success : 'true',
                message : 'User Added Successfully'
            };

            callback(res);
        }

    })
    .catch((error, res) => {
        console.log(error, res);
    })

}