import "./Register.css";
import { useForm } from 'react-hook-form';
import { loginServerRequest, registerServerRequest } from "../../functions/UserLogin";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, logout } from "../../actions";


const Register = () => {
    
    const { register, handleSubmit } = useForm();
    const isLogged = useSelector(state => state.isLogged);
    const dispatch = useDispatch();

    const onSubmit = (formData) =>{
        // console.log(formData)

        registerServerRequest(formData, (res) => {
            // console.log(res);

            if(res.success === 'false') {
                if(res.field === 'email') {
                    document.getElementById('registeremail').style.border = '2px solid red';
                } else if (res.field === 'username') {
                    document.getElementById('registerusername').style.border = '2px solid red';
                }
            } else if (res.success === 'true') {
                const inputfields = document.querySelectorAll('input');
                
                for(var elem of inputfields)
                    elem.style.border = '1px solid blue';

                const loginformData = {
                    loginemail : formData.registeremail,
                    loginpass : formData.registerpassword
                }

                loginServerRequest(loginformData, (res) => {
                    // console.log(res)
        
                    if (res.success == 'true') {
                        dispatch(login())
                    } else {
                        dispatch(logout())
                        alert('Wrong Credentials');
                    }
                })
                
            }

        })

    }
    
    if (isLogged) {
        return <Navigate to='/console' />
    }  


    return (
        <div className="Register">
            <div className="RegisterContainer">
                <h1>Signup</h1>
                <div className="RegisterContainerForm">

                    <form onSubmit = {handleSubmit(onSubmit)}>
                        <input {...register('registerfullname')} id="registerfullname" type = "text" name = "registerfullname" placeholder = 'Full Name' required />
                        <input {...register('registerusername')} id="registerusername" type = "text" name = "registerusername" placeholder = 'Username' required />
                        <input {...register('registeremail')} id="registeremail" type = "email" name = "registeremail" placeholder = 'Email Address' required />
                        <input {...register('registerpassword')} id="registerpassword" type = "password" name = "registerpassword" placeholder = 'Password' required />
                        <input {...register('registerreferralid')} id="registerreferralid" type = "text" name = "registerreferralid" placeholder = 'Referral ID (optional)' />
                        <input type="submit" value="Register" />
                    </form>


                    <div className="RegisterContainerExtra">
                        <span><a href="#">Lorem ipsum dolor sit amet</a></span>
                        <span>Already Registered? <a href="#">Signin</a></span>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Register;
