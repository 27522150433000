import './Order.css';

import Orderlist from '../OrderList/OrderList';
import Addorder from '../Addorder/Addorder';
import { useSelector, useDispatch } from 'react-redux';
import { showAddOrderPageAction, hideAddOrderPageAction } from '../../actions';



const Order = () => {

    const showAddOrder = useSelector(state => state.showAddOrderReducer);
    const dispatch = useDispatch();

    return (
        <div className='OrderComponent'>
            <div className="OrderComponentHeading">
                <h1>Orders</h1>
                {
                    showAddOrder == 'false' ?
                    <button onClick={() => dispatch(showAddOrderPageAction())}>Add New Order</button> :
                    <button onClick={() => dispatch(hideAddOrderPageAction())}>Show All Orders</button>
                }

            </div>                

            { showAddOrder == 'false' ? <Orderlist /> : <Addorder /> }

        </div>
    );

} 
    



export default Order;
