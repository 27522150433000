import cryptoJS from "crypto-js";
import axios from "axios";
import QueryString from "qs";


export const TransactionDataServerRequest = (callback) => {
    console.log('TransactionDataServerRequest');

    const finalResponse = [];
    
    // Fetch User Details from Local Storage
    let userdetails = window.localStorage.getItem('userdetails');
    userdetails = cryptoJS.AES.decrypt(userdetails, process.env.REACT_APP_CRYPTO_SECRET_KEY);
    userdetails = JSON.parse(userdetails.toString(cryptoJS.enc.Utf8))

    const uid = userdetails.id;
    
    // Proxy URL
    var url = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : '';
    url = url.concat(`central/transaction/user/${uid}`);

    
    // Options for Axios Request
    const options = {
        method : 'GET',
        url : url,
    };


    // Axios GET Request for Transaction Data
    axios(options)
    .then((response) => {
        // console.log(response.data);

        for(let i=0; i<response.data.length; i++) {
            
            const currData = response.data[i];            
            const tempData = {
                id          : currData.id,
                t_no        : currData.transaction_no,
                date        : currData.date.substring(0,10),
                time        : currData.time,
                bank_name   : currData.bank_name,
                amount      : currData.amount,
                medium      : currData.medium,
                status      : currData.status,
                reason      : currData.reason,
            }
            
            finalResponse.push(tempData);
        }

        callback(finalResponse);
    })
    .catch((error) => {
        console.log(error);
    })


}



export const addTransactionServerRequest = (data, callback) => {

    // console.log(data);
    
    // Fetch User Details from Local Storage
    let userdetails = window.localStorage.getItem('userdetails');
    userdetails = cryptoJS.AES.decrypt(userdetails, process.env.REACT_APP_CRYPTO_SECRET_KEY);
    userdetails = JSON.parse(userdetails.toString(cryptoJS.enc.Utf8))

    const uid = userdetails.id;
    const currDate = new Date().toISOString().replace('T', ' ')
    
    // Creating Request Body for Server POST Request
    const reqbody = {
        title : data.addTitle,
        ppc : data.addPpc,
        url : data.addUrl,
        status : 1,
        created_date : currDate.substring(0, currDate.length-5),
        updated_date : '0000-00-00 00:00:00',
        platform_id: data.addPlatform,
        type_id: data.addType,
        service_id: data.addService,
        user_id: uid
    }

    // Fetching URL
    var url = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : '';
    url = url.concat(`operation/transaction`);
    
    const options = {
      method : 'POST',
      url : url,
      data : QueryString.stringify(reqbody),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    };

    axios(options)
    .then((response) => {
        
        // console.log('Transaction Added Successfully')  
        const res = {
            success : true,
            message : 'Transaction Added Successfully'
        };
        
        callback(res);
    })
    .catch((error) => {
        // console.log(error.response.status)

        const res = {
            success : false,
            message : 'Internal Server Error : 500. Please check your Internet Connection and try again.'
        };

        callback(res);
    })

}