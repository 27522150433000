import './Transaction.css';

import { useDispatch, useSelector } from 'react-redux';
import { hideAddTransactionPageAction, showAddTransactionPageAction } from '../../actions';
import Addorder from '../Addorder/Addorder';
import Transactionlist from '../TransactionList/TransactionList';

const Transaction = () => {
    const showAddTransaction = useSelector(state => state.showAddTransactionReducer);
    const dispatch = useDispatch();

    return (
        <div className='TransactionComponent'>
            <div className="TransactionComponentHeading">
                <h1>Transactions</h1>
                {
                    showAddTransaction == 'false' ?
                    <button onClick={() => dispatch(showAddTransactionPageAction())}>Add New Transaction</button> :
                    <button onClick={() => dispatch(hideAddTransactionPageAction())}>Show All Transactions</button>
                }
            </div>                

            { showAddTransaction == 'false' ? <Transactionlist /> : <Addorder /> }

        </div>
    );
}

export default Transaction;