import Login from '../../components/Login/Login';
import Register from '../../components/Register/Register';

import './LoginPage.css'

const LoginPage = () => {
    return (
        <div className='LoginPage'>
            <div className='LoginPageFormSection'>

                <Login />

                <h2>OR</h2>
                
                <Register />
            </div>
        </div>
    );
}

export default LoginPage;
