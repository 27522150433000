import './Login.css'

import { Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { loginServerRequest } from "../../functions/UserLogin";
import { login, logout } from "../../actions";


const Login = () => {

    const dispatch = useDispatch();

    const onSubmit = (formData) =>{
        console.log(formData)
        
        loginServerRequest(formData, (res) => {
            console.log(res)

            if (res.success == 'true') {
                dispatch(login())
            } else {
                dispatch(logout())
                alert('Wrong Credentials');
            }
        })
    } 

    const { register, handleSubmit } = useForm();
    const isLogged = useSelector(state => state.isLogged);

    if (isLogged) {
        return <Navigate to='/console' />
    }  

    return (
        <div className='Login'>
            <div className="LoginContainer">
                <h1>Signin</h1>

                <div className="LoginContainerForm">
                    
                    <form onSubmit = {handleSubmit(onSubmit)}>
                        <input {...register('loginemail')} type = "email" name = "loginemail" placeholder = 'Email Address' />
                        <input {...register('loginpass')} type = "password" name = "loginpass" placeholder = 'Password' />
                            
                        <input type="submit" value="Login" />
                    </form>

                    <div className="LoginContainerExtra">
                        <span><a href="#">Forgot Password</a></span>
                        <span><a href="#">FREE REGISTRATION</a></span>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Login;
