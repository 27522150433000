import { useDispatch } from 'react-redux';
import './ProfileSidebar.css';
import { profileComponentAction, orderComponentAction, subscriptionComponentAction, transactionComponentAction, activityComponentAction } from "../../../actions";


const ProfileSidebar = () => {

    const dispatch = useDispatch();

    return (
        <div className='ProfileSidebar'>
            <h2 className="ProfileSidebarHeading">Dashboard</h2>
            
            <ul>
                <li onClick={() => dispatch(profileComponentAction())}>Profile</li>
                <li onClick={() => dispatch(orderComponentAction())}>Orders</li>
                <li onClick={() => dispatch(subscriptionComponentAction())}>Subscriptions</li>
                {/* <li onClick={() => dispatch(activityComponentAction())}>Activities</li> */}
                <li onClick={() => dispatch(transactionComponentAction())}>Transactions</li>
            </ul>
        </div>
    );
}

export default ProfileSidebar;
