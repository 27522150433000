import './ProfilePage.css';
import ProfileSidebar from "../../components/Sidebar/Profile/ProfileSidebar";
import RightcontainerProfile from '../../components/RightcontainerProfile/RightcontainerProfile';



const Profilepage = () => {


    return (
        <div className='ProfilePage'>
            <ProfileSidebar />
            <RightcontainerProfile />
        </div>
    );
}

export default Profilepage;
