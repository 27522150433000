import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import './Home.css';

const Home = () => {
    
    const isLogged = useSelector(state => state.isLogged);

    if (isLogged) {
        return <Navigate to='/console' />
    }

    return (
        <div className='HomePage'>
            <img src="https://images.unsplash.com/photo-1611926653458-09294b3142bf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="cover page"/>
        </div>
    );
}

export default Home;
