import './TransactionList.css'
import React, { useState } from 'react';

import { TransactionDataServerRequest } from "../../functions/Transaction";


const Transactionlist = () => {

    
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState('true');

    function TransactionDataRequestHelper() {
    
        TransactionDataServerRequest((data) => {
            // console.log("Before Callback\n Transactions => ", data);
            
            setData(data)
            setIsLoading('false')
        })
    };

    if(isLoading == 'true') {
        TransactionDataRequestHelper();
        // console.log(data);
    }


    return (
        <div>
            <div className="TransactionComponentTableContainer">
                <table className="TransactionComponentTable">
                    <thead>
                        <tr>
                            <td className='TransactionTableColumnHeader'>Transaction No</td>
                            <th className='TransactionTableColumnHeader' scope="col">Time</th>
                            <th className='TransactionTableColumnHeader' scope="col">Bank Name</th>
                            <th className='TransactionTableColumnHeader' scope="col">Amount</th>
                            <th className='TransactionTableColumnHeader' scope="col">Medium</th>
                            <th className='TransactionTableColumnHeader' scope="col">Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((row) => (
                                <tr key={row.id} data-transaction-status={row.status} className='TransactionTableRecordRow'>
                                    <th className='TransactionTableCell' key={row.t_no}>{row.t_no}</th>
                                    <td className='TransactionTableCell' key={row.date + '\n' + row.time}>{row.date + '\n' + row.time}</td>
                                    <td className='TransactionTableCell' key={row.bank_name}>{row.bank_name}</td>
                                    <td className='TransactionTableCell' key={row.amount}>Rs. {row.amount}</td>
                                    <td className='TransactionTableCell' key={row.medium}>{row.medium}</td>
                                    <td className='TransactionTableCell' key={row.reason}>{row.reason}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Transactionlist;
