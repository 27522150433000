import './SubscriptionList.css'
import React, { useState } from 'react';

import { SubscriptionDataServerRequest } from "../../functions/Subscription";


const Subscriptionlist = () => {

    
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState('true');

    function SubscriptionDataRequestHelper() {
    
        SubscriptionDataServerRequest((data) => {
            // console.log("Before Callback\n Subscriptions => ", data);
            
            setData(data)
            setIsLoading('false')
        })
    };

    if(isLoading == 'true') {
        SubscriptionDataRequestHelper();
        // console.log(data);
    }


    return (
        <div>
            <div className="SubscriptionComponentTableContainer">
                <table className="SubscriptionComponentTable">
                    <thead>
                        <tr>
                            <td className='SubscriptionTableColumnHeader'>Point</td>
                            <th className='SubscriptionTableColumnHeader' scope="col">Bank Name</th>
                            <th className='SubscriptionTableColumnHeader' scope="col">Date</th>
                            <th className='SubscriptionTableColumnHeader' scope="col">Amount</th>
                            <th className='SubscriptionTableColumnHeader' scope="col">Medium</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((row) => (
                                <tr date-id={row.id}>
                                    <th className='SubscriptionTableCell'>{row.point}</th>
                                    <td className='SubscriptionTableCell'>{row.bank_name}</td>
                                    <td className='SubscriptionTableCell'>{row.date}</td>
                                    <td className='SubscriptionTableCell'>{row.amount}</td>
                                    <td className='SubscriptionTableCell'>{row.medium}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Subscriptionlist;
