import './RightcontainerProfile.css'

import Profile from '../Profile/Profile'
import Order from '../Order/Order'
import Subscription from '../Subscription/Subscription'
import Transaction from '../Transaction/Transaction'
import Activity from '../Activity/Activity'

import { useSelector } from 'react-redux';


const Rightcontainer = () => {
    
    const currentComponent = useSelector(state => state.profilePageReducer);

    let component;
    if (currentComponent == 'profile')
        component = <Profile />
    else if (currentComponent == 'order')
        component = <Order />
    else if (currentComponent == 'subscription')
        component = <Subscription />
    else if (currentComponent == 'transaction')
        component = <Transaction />
    else if (currentComponent == 'activity')
        component = <Activity />



    return (
        <div className='RightcontainerProfileComponent'>
            {component}
        </div>
    );
}

export default Rightcontainer;
