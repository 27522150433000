const profilePageReducer = (state = 'profile', action) => {
    switch (action.type) {
        case 'PROFILE':
            return 'profile';  
        case 'ORDER':
            return 'order';  
        case 'SUBSCRIPTION':
            return 'subscription';
        case 'TRANSACTION':
            return 'transaction';
        case 'ACTIVITY':
            return 'activity';
        default:
            return state
    }
}


export default profilePageReducer;