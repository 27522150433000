import { combineReducers } from "redux";

import loggedReducer from "./isLogged";
import profilePageReducer from "./profilePageTab";
import showAddOrderReducer from "./order";
import showAddSubscriptionReducer from "./subscription";
import showAddTransactionReducer from "./transaction";


const reducers = combineReducers({
    isLogged : loggedReducer,
    profilePageReducer : profilePageReducer,
    showAddOrderReducer : showAddOrderReducer,
    showAddSubscriptionReducer : showAddSubscriptionReducer,
    showAddTransactionReducer : showAddTransactionReducer,
})


export default reducers;