import './Addorder.css';

import { useSelector, useDispatch } from 'react-redux';
import { hideAddOrderPageAction } from '../../actions';
import { useForm } from 'react-hook-form';
import { addOrderServerRequest } from '../../functions/Order';

const Addorder = () => {

    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();

    const onSubmit = (formData) => {
        // console.log('Submit Order', formData);
        addOrderServerRequest(formData, function (res) {
            // console.log(res);
            if(res.success) {
                console.log(res)
                alert(res.message)
                dispatch(hideAddOrderPageAction())
            } else {
                console.log(res.message)
                alert(res.message)
            }
        })
    }


    return (
        <div className='AddorderComponent'>
            <h1>Add Order</h1>


            <form onSubmit = {handleSubmit(onSubmit)} className='AddorderComponentForm'>
                
                <div>
                    <label htmlFor="addTitle">Title</label>
                    <input {...register('addTitle')} id="addTitle" type = "text" name = "addTitle" placeholder = 'Title' required />
                </div>
                

                <div>
                    <label htmlFor="addUrl">URL</label>
                    <input {...register('addUrl')} id="addUrl" type = "url" name = "addUrl" placeholder = 'URL' required />
                </div>

                <div className="row ppc-and-platform">
                    <div className='col'>
                        <label htmlFor="addPpc">PPC</label>
                        <input {...register('addPpc')} id="addPpc" type = "number" name = "addPpc" placeholder = 'PPC' required min={5} step={5} />
                    </div>
                    
                    <div className='col'>
                        <label htmlFor="addPlatform">Platform</label>
                        <select {...register('addPlatform')} id="addPlatform"  list="browsers" name = "addPlatform" placeholder = 'Platform' required >
                            <option value="1">Facebook</option>
                            <option value="2">Twitter</option>
                            <option value="3">Instagram</option>
                            <option value="4">Youtube</option>
                            <option value="5">Website</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className='col'>
                        <label htmlFor="addType">Type</label>
                        <select {...register('addType')} id="addType" name = "addType" placeholder = 'Type' required >
                            <option value="1">Post</option>
                            <option value="2">Profile</option>
                            <option value="3">Channel</option>
                            <option value="4">Page</option>
                            <option value="5">Website</option>
                            <option value="6">Others</option>
                        </select>
                    </div>

                    <div className='col'>
                        <label htmlFor="addService">Service</label>
                        <select {...register('addService')} id="addService" name = "addService" placeholder = 'Service' required >
                            <option value="1">Like</option>
                            <option value="2">Comment</option>
                            <option value="3">Subscribe</option>
                            <option value="4">Share</option>
                            <option value="5">Hit</option>
                            <option value="6">Follow</option>
                            <option value="7">Retweet</option>
                            <option value="8">Others</option>
                        </select>
                    </div>
                </div>


                <input type="submit" value="Place Order" className='AddorderSubmitButton' />
            </form>
        </div>
    );
}

export default Addorder;
