import "./Console.css"

import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import RightcontainerConsole from '../../components/RightcontainerConsole/RightcontainerConsole';
import ConsoleSidebar from "../../components/Sidebar/Console/Sidebar";

const Console = () => {

    const isLogged = useSelector(state => state.isLogged);
    
    if (!isLogged) {
        return <Navigate to='/login' />
    }    

    return (
        <div className="ConsolePage">
            <ConsoleSidebar />
            <RightcontainerConsole />
        </div>
    );
}

export default Console;
