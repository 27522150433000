import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


import './App.css';
import Topbar from './components/Topbar/Topbar';
import Home from "./pages/Home/Home";
import LoginPage from "./pages/LoginPage/LoginPage";
import Console from "./pages/Console/Console";
import Profilepage from "./pages/ProfilePage/ProfilePage";
import { login } from "./actions";


function App() {

  const dispatch = useDispatch();

  const checkLoginState = () => {
    const isLoggedIn = window.localStorage.getItem('isLoggedIn');
    if(isLoggedIn === '1') return dispatch(login());
    else window.localStorage.setItem('isLoggedIn', '0');
  }


  return (
    <div className="App" onLoad={checkLoginState()}>
      <Router>  
        <Topbar/>
        
        <div className="AppAfterTopbar">
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/login' element={<LoginPage />}/>
            <Route path='/console' element={<Console />}/>
            <Route path='/profile' element={<Profilepage />}/>
          </Routes>
        </div>
      </Router>
        
      </div>
  );
}

export default App;
