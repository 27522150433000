import "./RightcontainerConsole.css"
import { Route, Routes } from "react-router-dom";

import Login from "../Login/Login";
import { autoLaunchExample } from "../../functions/Automation";

const RightcontainerConsole = () => {

    function openNewWindow() {
        console.clear()
        const myWindow = window.open("https://google.com", "MsgWindow", "width=500,height=1000");
        // myWindow.document.write("<p>This is 'MsgWindow'. I am 200px wide and 100px tall!</p>");
        
        setTimeout(() => {
            myWindow.opener.console.log('opener')
            console.log(myWindow.window.document)
        }, 5000);
    }


    return (
        <div className='RightcontainerConsole'>
            Right Inner Container
            {/* <button onClick={() => autoLaunchExample()}>click here</button> */}
            <Routes>
                <Route path='/l2' element={<Login/>}/>
            </Routes>
        </div>
    );
}

export default RightcontainerConsole;
