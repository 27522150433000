import './OrderList.css'
import React, { useState } from 'react';

import { OrderDataServerRequest } from "../../functions/Order";


const Orderlist = () => {

    
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState('true');

    function OrderDataRequestHelper() {
    
        OrderDataServerRequest((data) => {
            // console.log("Before Callback\n Orders => ", data);
            
            setData(data)
            setIsLoading('false')
        })
    };

    if(isLoading == 'true') {
        OrderDataRequestHelper();
        // console.log(data);
    }


    return (
        <div>
            <div className="OrderComponentTableContainer">
                <table className="OrderComponentTable">
                    <thead>
                        <tr>
                            <td className='OrderTableColumnHeader'>Title</td>
                            <th className='OrderTableColumnHeader' scope="col">Platform</th>
                            <th className='OrderTableColumnHeader' scope="col">PPC</th>
                            <th className='OrderTableColumnHeader' scope="col">URL</th>
                            <th className='OrderTableColumnHeader' scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((row) => (
                                <tr>
                                    <th className='OrderTableCell'>{row.title}</th>
                                    <td className='OrderTableCell'>{row.platform}</td>
                                    <td className='OrderTableCell'>{row.ppc}</td>
                                    <td className='OrderTableCell'>{row.url}</td>
                                    <td className='OrderTableCell'>{row.status}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Orderlist;
