import './Topbar.css'

import { RiLoginBoxFill, RiLogoutBoxRFill } from 'react-icons/ri';
import { MdOutlineCreateNewFolder,MdAccountBalanceWallet, MdPersonAdd } from 'react-icons/md';

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { logout, profileComponentAction } from "../../actions";


const Topbar = () => {
    
    const isLogged = useSelector(state => state.isLogged);
    const dispatch = useDispatch();
    
    return (
        <div className='Topbar'>
            <div className="TopbarContainer">
                
                <div className="TopbarContainerLeft">
                    <Link to="/" className='text-link'>
                        <span className="TopbarLogo">addmenow</span>
                    </Link>
                </div>
                
                <div className="TopbarContainerRight">
                    
                    {isLogged ? 
                    <>
                        <Link to="/profile" className='text-link'>
                            <span className="TopbarButton" onClick={() => dispatch(profileComponentAction())}><MdAccountBalanceWallet className='LinkBadge'/>Profile</span>
                        </Link>

                        <Link to="/console" className='text-link'>
                            <span className="TopbarButton"><MdAccountBalanceWallet className='LinkBadge'/>Console</span>
                        </Link>
                    
                        <Link to="/" className='text-link'>
                            <span onClick={() => dispatch(logout())} className="TopbarButton"><RiLogoutBoxRFill className='LinkBadge'/>Logout</span>
                        </Link>
                    </> :
                    <>
                        <Link to="/login" className='text-link'>
                            <span className="TopbarButton"><RiLoginBoxFill className='LinkBadge'/>Login</span>
                        </Link>

                        <Link to="/login" className='text-link'>
                            <span className="TopbarButton"><MdPersonAdd className='LinkBadge'/>Register</span>
                        </Link>
                    </>
                    }
                </div>  
            </div>
        </div>
    );
}

export default Topbar;
