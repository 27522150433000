import './Subscription.css';

import { useDispatch, useSelector } from 'react-redux';
import { hideAddSubscriptionPageAction, showAddSubscriptionPageAction } from '../../actions';
import Addorder from '../Addorder/Addorder';
import Subscriptionlist from '../SubscriptionList/SubscriptionList';

const Subscription = () => {
    const showAddSubscription = useSelector(state => state.showAddSubscriptionReducer);
    const dispatch = useDispatch();

    return (
        <div className='SubscriptionComponent'>
            <div className="SubscriptionComponentHeading">
                <h1>Subscriptions</h1>
                {
                    showAddSubscription == 'false' ?
                    <button onClick={() => dispatch(showAddSubscriptionPageAction())}>Add New Subscription</button> :
                    <button onClick={() => dispatch(hideAddSubscriptionPageAction())}>Show All Subscriptions</button>
                }
            </div>                

            { showAddSubscription == 'false' ? <Subscriptionlist /> : <Addorder /> }

        </div>
    );
}

export default Subscription;