import './Profile.css';
import cryptoJS from "crypto-js";

import { FaUserAlt } from "react-icons/fa";

const Profile = () => {

    var userdetails = window.localStorage.getItem('userdetails');
    userdetails = cryptoJS.AES.decrypt(userdetails, process.env.REACT_APP_CRYPTO_SECRET_KEY);
    userdetails = JSON.parse(userdetails.toString(cryptoJS.enc.Utf8))

    console.log(userdetails);
    
    function loadNotificationStatus() {
        setTimeout(() => {
            if(userdetails.notification === 1) {
                document.getElementsByName('ProfileNotification')[0].checked = true;
                console.log(document.getElementById('ProfileNotification'))
            }        
        }, 400);
    }

    return (
        <div className='ProfileComponent'>
            <div className="ProfileComponentHeading"><h2>Profile Details</h2> <FaUserAlt /> </div>
            
            <div className="ProfileComponentContent">

                <div className='ProfileComponentLeftSection'>
                    <div className="ProfileComponentUserPictureDiv">
                        <div className="ProfileComponentUserPicture">
                            {userdetails.full_name[0]} 
                            {/* <FaUserAlt /> */}
                        </div>
                    </div>
                </div>
                    
                <div className='ProfileComponentUserdetail'>

                    <div className="ProfileComponentUserFullname">
                        <label htmlFor="ProfileFullname">Full Name</label> 
                        <input type="text" name="ProfileFullname" id="ProfileFullname" value={userdetails.full_name}   /> 
                    </div>
                    
                    <div className="ProfileComponentUserEmailid">
                        <label htmlFor="ProfileEmailid">Email ID</label> 
                        <input type="email" name='ProfileEmailid' id='ProfileEmailid' value={userdetails.email_id}   />
                    </div>
                    
                    <div className="ProfileComponentUserUsername">
                        <label htmlFor="ProfileUsername">Username</label> 
                        <input type="text" name="ProfileUsername" id="ProfileUsername" value={userdetails.user_name}   />
                    </div>
                    
                    <div className="ProfileComponentUserPoint">
                        <label htmlFor="ProfilePoint">Point</label> 
                        <input type="text" name="ProfilePoint" id="ProfilePoint" value={userdetails.point}   />
                    </div>
                    
                    <div className="ProfileComponentNotification">
                        <label htmlFor="ProfileNotification">Notification</label>
                        <input type="checkbox" name="ProfileNotification" id="ProfileNotification" onLoad={loadNotificationStatus()}   />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
