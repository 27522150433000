import "./Sidebar.css"
import { FaChrome, FaFacebook, FaTwitter } from 'react-icons/fa';
import { MdAddCircle } from 'react-icons/md';
import { RiInstagramFill, RiShoppingBasketFill } from 'react-icons/ri';
import { BiCoinStack } from 'react-icons/bi';

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { orderComponentAction, showAddOrderPageAction } from "../../../actions";



const Sidebar = () => {
    
    const dispatch = useDispatch()
    
    return (
        <div className='Sidebar'>
    
            <div className="SidebarProfile">
                <h2 className="ServiceProfileHeading SidebarChildHeading">Profile</h2>

                <Link  to="/profile">
                    <div className="ServiceProfileChild SidebarChildLink" onClick={() => { 
                        dispatch(orderComponentAction()); 
                        dispatch(showAddOrderPageAction())
                    } 
                    }>
                        <MdAddCircle /> <span> Add New Order</span>
                    </div>
                </Link>

                <Link  to="/profile">
                    <div className="ServiceProfileChild SidebarChildLink" onClick={() => dispatch(orderComponentAction()) }>
                        <RiShoppingBasketFill /> <span> My Orders</span>
                    </div>
                </Link>
                
                <div className="ServiceProfileChild SidebarChildLink">
                    <BiCoinStack /> <span> Buy Points</span>
                </div>

            </div>

        
            <div className="SidebarService">
                <h2 className='SidebarServiceHeading SidebarChildHeading'>Services</h2>

                <div className="SidebarServiceChild SidebarChildLink"><FaFacebook /> <span href="#"> Facebook Likes </span> </div>
                <div className="SidebarServiceChild SidebarChildLink"><FaFacebook /> <span href="#"> Facebook Share </span> </div>
                <div className="SidebarServiceChild SidebarChildLink"><FaFacebook /> <span href="#"> Facebook Followes </span> </div>
                <div className="SidebarServiceChild SidebarChildLink"><FaFacebook /> <span href="#"> Facebook Post Like </span> </div>
                <div className="SidebarServiceChild SidebarChildLink"><FaFacebook /> <span href="#"> Facebook Post Share </span> </div>


                <div className="SidebarServiceChild SidebarChildLink"><RiInstagramFill /> <span href="#"> Instagram Likes </span> </div>
                <div className="SidebarServiceChild SidebarChildLink"><RiInstagramFill /> <span href="#"> Instagram Followers </span> </div>
                

                <div className="SidebarServiceChild SidebarChildLink"><FaTwitter /> <span href="#"> Twitter Likes </span> </div>
                <div className="SidebarServiceChild SidebarChildLink"><FaTwitter /> <span href="#"> Twitter Followers </span> </div>
                
                
                <div className="SidebarServiceChild SidebarChildLink"><FaChrome /> <span href="#"> Website Hits </span> </div>
            </div>

        </div>
    );
}


export default Sidebar;
