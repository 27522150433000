import './Activity.css';

const Activity = () => {
    return (
        <div>
            Activity
        </div>
    );
}

export default Activity;
