import { logoutUser } from "../functions/UserLogin";


export const login = () => {
    return {
        type : 'LOGIN'
    };
};


export const logout = () => {

    logoutUser();

    return {
        type : 'LOGOUT'
    };
};



export const orderComponentAction = () => {
    return {
        type : 'ORDER'
    };
}


export const profileComponentAction = () => {
    return {
        type : 'PROFILE'
    };
}


export const subscriptionComponentAction = () => {
    return {
        type : 'SUBSCRIPTION'
    };
}


export const transactionComponentAction = () => {
    return {
        type : 'TRANSACTION'
    };
}


export const activityComponentAction = () => {
    return {
        type : 'ACTIVITY'
    };
}


export const showAddOrderPageAction = () => {
    return {
        type : 'SHOW'
    };
}


export const hideAddOrderPageAction = () => {
    return {
        type : 'HIDE'
    };
}


export const showAddSubscriptionPageAction = () => {
    return {
        type : 'SHOW'
    };
}


export const hideAddSubscriptionPageAction = () => {
    return {
        type : 'HIDE'
    };
}



export const showAddTransactionPageAction = () => {
    return {
        type : 'SHOW'
    };
}


export const hideAddTransactionPageAction = () => {
    return {
        type : 'HIDE'
    };
}